.faq-info-block {
  .accordion {
    .card {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;

      .card-header {
        border-bottom: 0;
        background-color: white;
        cursor: pointer;
        position: relative;
        padding-right: 30px;

        h6 {
          position: relative;

          .chevron-down {
            position: absolute;
            right: -32px;
            top: 2px;
            opacity: 0;
            transition: opacity 200ms 0ms;
          }
        }
      }

      .card-body {
        padding-bottom: 64px;

        .gilroy-reg-24 {
          font-size: 18px;
        }
      }

      &:not(.active) {
        h6 {
          font-size: 18px;
          color: rgba(40, 40, 40, 0.5);
          .chevron-down {
            opacity: 1;
          }
        }
      }
    }
  }
}
