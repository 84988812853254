.mod-tech-img-report-them {
  position: relative;
  height: 100%;
  width: 100%;

  .create-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
  }

  .bottom-buttons-icon {
    position: absolute;
    top: 70%;
    left: 10%;
    width: 66%;
  }

  .three-green-lines {
    position: absolute;
    top: 3%;
    left: 25%;
    width: 18%;
  }
}
