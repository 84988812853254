.reset-inner {
  h2 {
    margin-bottom: 5%;
  }

  .small-poppins {
    color: rgba(123, 123, 123, 0.8);
    font-size: 14px;
    margin-bottom: 5%;
  }
}
