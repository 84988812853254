@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.login-window {
  width: 100vw;
  height: 100vh;
  background-color: rgba(234, 237, 242, 0.5);

  .inner-white {
    width: 646px;
    padding: 60px 40px 30px 40px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
      0px 0px 2px rgba(0, 0, 0, 0.302);
    border-radius: 10px;
  }
}

@include media-breakpoint-down(md) {
  .login-window {
    .inner-white {
      padding: 20px 10px;
    }
  }
}
