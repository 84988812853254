@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.more-than-app-section {
  background-color: #fbfbfb;
  padding-bottom: 5%;
  position: relative;

  .header-col {
    h3 {
      font-size: 42px;
      margin-top: 9%;
      margin-bottom: 9%;
      position: relative;
      z-index: 2;

      span {
        position: relative;
        color: white;

        .birthmark {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 106%;
          z-index: -1;
        }
      }
    }
  }

  .elements {
    .element {
      padding: 0 5% 5% 5%;
      img {
        margin-bottom: 16px;
        width: 75px;
      }
      h5 {
        margin-bottom: 8px;
      }
      p {
        font-family: gilroy-regular;
        font-size: 18px;
        line-height: 120%;
      }
    }
  }

  .not-everything-row {
    margin-top: 5%;

    p {
      margin-top: 2%;
      margin-bottom: 4%;
      line-height: 200%;
    }

    .btn {
      width: 30%;
    }

    h3 {
      z-index: 2;

      span {
        position: relative;

        .birthmark {
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: -1;
          width: 110%;
        }
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .more-than-app-section {
    .not-everything-row {
      p {
        font-size: 26px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .more-than-app-section {
    .header-col {
      h3 {
        font-size: 24px;
      }
    }

    .elements {
      .element {
        padding: 0 3% 3% 3%;
        img {
          margin-bottom: 8px;
          width: 44px;
        }
        p {
          font-size: 14px;
        }
      }
    }

    .not-everything-row {
      .btn {
        width: 50%;
      }
    }
  }
}

@include media-breakpoint-down(md) {
}

@include media-breakpoint-down(sm) {
  .more-than-app-section {
    .not-everything-row {
      p {
        font-size: 22px;
      }
    }
  }
}
