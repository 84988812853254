.back-link {
  display: block;
  margin-bottom: 24px;

  .left-chevron {
    padding-right: 12px;
  }

  a {
    display: flex;
    margin-bottom: 36px;
  }
}
