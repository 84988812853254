@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.dashboard-section {
  position: relative;
  margin-bottom: 100px;

  .blue-rectangle {
    z-index: -1;
    position: absolute;
    top: 0;
    height: 714px;
    width: 100%;
    background-color: #edeefe;
  }

  .first-row {
    padding-top: 10%;
  }

  .green-marks-row {
    margin-bottom: 10%;
    margin-top: 2%;

    .green-mark {
      margin-top: 6%;
    }
  }

  .request-demo-button-row {
    margin-bottom: 5%;
    .btn {
      width: 214px;
    }
  }

  .mac-row {
    .mac-img {
      width: 80%;
      margin-bottom: 10%;
    }

    .circle-num {
      background-color: #ffeeb5;
      width: 50px;
      height: 50px;
      margin-bottom: 10%;

      h3 {
        margin: 0;
      }
    }

    .carousel {
      p {
        padding-left: 15%;
        padding-right: 15%;
      }
    }
  }

  .border-row {
    margin: 128px 0;
    border-bottom: 1px solid #e0e0e0;
  }

  .get-val-row {
    h2 {
      margin-bottom: 10%;
    }

    .main11 {
      width: 120%;
      margin-left: -10%;
      margin-top: -10%;
    }

    .right-side-block {
      position: absolute;
      bottom: 15%;

      h4 {
        margin-bottom: 5%;
      }

      p {
        margin-bottom: 5%;
      }
    }

    .rectangle2100,
    .rectangle2100-2 {
      position: absolute;
      top: 0;
      z-index: -1;
    }

    .rectangle2100 {
      left: -20%;
    }

    .rectangle2100-2 {
      left: 0;
    }
  }

  .reporters-row,
  .suppliers-row {
    .medical-report1,
    .event-management2 {
      margin-bottom: 10%;
      width: 30%;
    }

    h2 {
      margin-bottom: 10%;
    }

    .rectangle2684,
    .rectangle2685 {
      width: 100%;
    }
  }

  .reporters-row {
    margin-top: 5%;

    .medical-report1 {
      margin-top: 30%;
    }

    p {
      padding-right: 10%;
    }
  }

  .suppliers-row {
    margin-top: 10%;

    .event-management2 {
      margin-top: 40%;
    }

    .text-col {
      padding-left: 5%;
    }
  }
}

@include media-breakpoint-only(lg) {
  .get-val-row {
    .right-side-block {
      top: 5%;
    }
  }
}

@include media-breakpoint-down(lg) {
  .dashboard-section {
    .blue-rectangle {
      height: 12%;
    }

    .get-val-row {
      h2 {
        padding-left: 5%;
      }

      .right-side-block {
        padding: 20% 5% 0 5%;
        position: relative;
      }
    }

    .reporters-row,
    .suppliers-row {
      .medical-report1,
      .event-management2 {
        margin-bottom: 10%;
        width: 20%;
      }

      p {
        margin-bottom: 10%;
      }
    }

    .reporters-row {
      .medical-report1 {
        margin-top: 0;
      }

      p {
        padding-right: 0;
      }
    }

    .suppliers-row {
      .text-col {
        padding-left: 0;
      }
    }
  }
}
