.mod-tech-img-contractors-recieve {
  position: relative;
  height: 100%;
  width: 100%;

  .group7927,
  .main-dasboard3 {
    position: absolute;
  }

  .main-dasboard3 {
    right: 0;
    top: 0;
    width: 100%;
  }

  .group7927 {
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 65%;
  }
}
