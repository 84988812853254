@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.about-section {
  h2 {
    padding-bottom: 3%;
  }

  .making-life-easier {
    padding-top: 5%;
    padding-bottom: 5%;

    .text-col {
      padding: 5%;
    }

    .rectangle2099 {
      width: 100%;
    }

    .rectangle2101 {
      position: absolute;
      top: 0;
      left: 0;
      width: 70%;
      z-index: -1;
    }

    .three-green-lines {
      position: absolute;
      bottom: 5%;
      right: 10%;
      width: 20%;
      z-index: -1;
    }
  }

  .pink-rectangle {
    background-color: #fef4ed;

    .we-are-olivux-row {
      padding-top: 5%;
      padding-bottom: 5%;

      .dsc036261 {
        width: 100%;
      }

      .text-col {
        padding: 5% 0 5% 5%;
        z-index: 2;

        .dots {
          position: absolute;
          bottom: 0;
          right: 30%;
          z-index: -1;
        }
      }
    }
  }

  .three-thesises-row,
  .vision-mission {
    padding: 10%;

    .icon {
      width: 15%;
      margin-bottom: 24px;
    }

    .col {
      padding-top: 10%;
      .icon {
        width: 30%;
      }
    }

    h4 {
      padding-bottom: 5%;
    }
  }

  .three-thesises-row {
    padding: 10% 10% 0 10%;
  }

  .vision-mission {
    padding: 0 10% 10% 10%;
  }

  .leadership-team {
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: #fef4ed;
  }
}

@include media-breakpoint-up(lg) {
  .about-section {
    .leadership-team {
      .right {
        padding-left: 5.5%;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .about-section {
    .making-life-easier {
      .rectangle2101 {
        width: 50%;
        max-height: 100%;
      }

      .rectangle2099 {
        padding-bottom: 5%;
      }
    }

    .pink-rectangle {
      .we-are-olivux-row {
        .dsc036261 {
          padding-bottom: 5%;
        }

        .text-col {
          padding-right: 5%;
        }
      }
    }

    .three-thesises-row {
      .icon {
        width: 30%;
      }
    }

    .leadership-team {
      .container {
        padding: 25px;
      }
    }
  }
}

@include media-breakpoint-only(md) {
  .about-section {
    .three-thesises-row,
    .vision-mission {
      .icon {
        width: 20%;
      }
    }
  }
}
