@import './variables.scss';

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'danger': $danger,
);

@import '~bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

@font-face {
  font-family: 'gilroy-bold';
  src: url('./assets/fonts/gilroy-bold.ttf');
}

@font-face {
  font-family: 'gilroy-regular';
  src: url('./assets/fonts/gilroy-regular.ttf');
}

@font-face {
  font-family: 'gilroy-semibold';
  src: url('./assets/fonts/gilroy-semibold.ttf');
}

@font-face {
  font-family: 'gilroy-medium';
  src: url('./assets/fonts/gilroy-medium.ttf');
}

html,
body {
  overflow-x: hidden;
}

body {
  position: relative;
}

.btn {
  border-radius: 6px;
  height: 64px;
  font-family: gilroy-bold;
  font-size: 18px;
  line-height: 21px;
}

.carousel {
  .carousel-indicators {
    bottom: -48px;

    li {
      background-color: #b3b3b3;
      border-radius: 50%;
      height: 12px;
      width: 12px;
      margin: 12px;
      cursor: pointer;

      &.active {
        background-color: $primary;
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
}

form {
  margin-bottom: 5%;

  input.form-control,
  label.form-label {
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 150%;
  }

  input.form-control,
  textarea.form-control {
    box-shadow: 0px 0px 0px 1px rgba(146, 146, 146, 0.48);
    border-radius: 9px;
    margin-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;

    &::placeholder {
      opacity: revert;
    }
  }

  input.form-control {
    height: 49px;
  }

  textarea.form-control {
    resize: none;
  }

  .btn {
    width: 100%;
    border-radius: 9px;
    height: 49px;
  }
}

a {
  text-decoration: none;
  font-family: Poppins;
  font-size: 16px;
}

a.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  font-family: gilroy-bold;
  font-size: 64px;
  line-height: 75px;
}

h2 {
  font-family: gilroy-bold;
  font-size: 54px;
  line-height: 63px;
}

h3 {
  font-family: gilroy-bold;
  font-size: 38px;
  line-height: 40px;
}

h4 {
  font-family: gilroy-bold;
  font-size: 32px;
  line-height: 37px;
}

h5 {
  font-family: gilroy-bold;
  font-size: 28px;
  line-height: 150%;
}

h6 {
  font-family: gilroy-bold;
  font-size: 20px;
  line-height: 150%;
}

.gilroy-reg-24 {
  font-family: gilroy-regular;
  font-size: 24px;
  line-height: 150%;
}

.gilroy-reg-19 {
  font-family: gilroy-regular;
  font-size: 19px;
  line-height: 150%;
}

.gilroy-semibold-17 {
  font-family: gilroy-semibold;
  font-size: 17px;
  line-height: 26px;
}

.gilroy-semibold-19 {
  font-family: gilroy-semibold;
  font-size: 19px;
  line-height: 26px;
}

.small-poppins {
  font-family: 'Poppins';
  font-size: 17px;
  line-height: 150%;
  color: #656565;
}

.footer-font {
  font-family: gilroy-regular;
  font-size: 20px;
  line-height: 150%;
}

.menu-gray-font {
  color: #9f9f9f;
  font-family: gilroy-medium;
  font-size: 14px;
  line-height: 16px;
}

@include media-breakpoint-down(xxl) {
  h1 {
    font-size: 56px;
    line-height: 64px;
  }

  h2 {
    font-size: 46px;
    line-height: 52px;
  }

  h3 {
    font-size: 34px;
    line-height: 40px;
  }

  h4 {
    font-size: 28px;
    line-height: 150%;
  }

  .gilroy-semibold-17 {
    font-size: 15px;
    line-height: 20px;
  }

  .gilroy-reg-24 {
    font-size: 22px;
    line-height: 28px;
  }
}

@include media-breakpoint-down(xl) {
  h1 {
    font-size: 48px;
    line-height: 56px;
  }

  h2 {
    font-size: 36px;
    line-height: 42px;
  }

  h3 {
    font-size: 30px;
    line-height: 38px;
  }

  h4 {
    font-size: 22px;
    line-height: 150%;
  }

  h6 {
    font-size: 18px;
  }

  .gilroy-semibold-17 {
    font-size: 12px;
    line-height: 18px;
  }

  .gilroy-reg-24 {
    font-size: 20px;
    line-height: 28px;
  }

  .gilroy-reg-19 {
    font-size: 16px;
  }
}

@include media-breakpoint-down(lg) {
  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 20px;
  }

  .btn {
    height: 42px;
    font-size: 14px;
    line-height: 16px;
  }

  .gilroy-reg-19 {
    font-size: 19px;
  }

  .gilroy-semibold-17 {
    font-size: 17px;
    line-height: 24px;
  }

  .small-poppins {
    font-size: 14px;
  }
}

@include media-breakpoint-down(md) {
  h1 {
    font-size: 32px;
    line-height: 37px;
  }

  h2 {
    font-size: 28px;
    line-height: 32px;
  }

  h3 {
    font-size: 24px;
    line-height: 30px;
  }

  h4 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  .footer-font {
    font-size: 16px;
  }

  .gilroy-reg-24 {
    font-family: gilroy-regular;
    font-size: 18px;
    line-height: 28px;
  }

  .gilroy-reg-19 {
    font-size: 16px;
  }
}

@include media-breakpoint-down(sm) {
  h3 {
    font-size: 18px;
    line-height: 30px;
  }

  .footer-font {
    font-size: 14px;
    line-height: 22px;
  }

  .gilroy-reg-24 {
    font-size: 14px;
    line-height: 22px;
  }

  .gilroy-reg-19 {
    font-size: 12px;
  }
}
