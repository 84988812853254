.mod-tech-img-find-issues-ask {
  height: 14%;
  position: relative;

  img {
    position: absolute;
  }

  .ellipse {
    width: 33%;
  }

  .large-title {
    width: 55%;
    right: 10%;
    top: 45%;
    transform: translate(0, -50%);

    &.se {
      width: 52%;
    }
  }

  .hint {
    width: 23%;
    left: 5%;
    top: 45%;
    transform: translate(0, -50%);
  }
}
