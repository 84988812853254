@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.request-section {
  padding-top: 5%;
  padding-bottom: 5%;

  .container {
    padding-left: 5%;
    padding-right: 5%;

    .rectangle20992 {
      width: 100%;
    }

    .dots,
    .rectangle2100,
    .three-green-lines,
    .rectangle2101 {
      position: absolute;
    }

    .dots {
      left: -20%;
      top: 55%;
    }

    .rectangle2100 {
      left: -10%;
      top: 70%;
      width: 50%;
    }

    .rectangle2101 {
      left: 40%;
      top: -10%;
      width: 50%;
    }

    .three-green-lines {
      left: 5%;
      top: -10%;
    }

    .form-col {
      padding-left: 5%;

      .first-notice {
        color: #4d4d4d;
        font-size: 18px;
      }

      .three-faces-box {
        padding-bottom: 5%;
      }

      .last-notice {
        color: #979797;
        font-size: 14px;

        .last-notice-link {
          font-family: gilroy-regular;
          font-size: 14px;
          text-decoration: underline;
        }
      }

      .rectangle2100,
      .rectangle2101 {
        position: absolute;
        z-index: -1;
      }

      .rectangle2100 {
        top: 15%;
        left: 60%;
      }

      .rectangle2101 {
        top: 5%;
        left: 0;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .request-section {
    .container {
      .form-col {
        padding-left: 12px;
        padding-top: 20%;
      }
    }
  }
}
