@import '../variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.mod-tech-tabs {
  .mod-tech-tab-left-side {
    h3 {
      margin-bottom: 64px;
      position: relative;
      z-index: 1;

      .birthmark {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        max-width: 75%;
        height: 50%;
      }
    }
  }

  .bottom-row {
    margin-top: 128px;
    height: 645px;

    .left-col {
      padding-right: 64px;
    }

    .right-col {
      padding-left: 128px;
    }
  }

  .tabs-switcher-row {
    margin-top: 64px;
    justify-content: space-between;

    .tab-switcher {
      width: fit-content;
      height: fit-content;

      &.current-tab {
        .tab-header {
          border-bottom: 3px solid $primary;
        }

        .mod-tech-tab-left-side {
          transition: all 300ms;
          height: 100%;
          opacity: 1;
        }
      }

      .text-row {
        cursor: pointer;

        a {
          color: black;
        }

        .tab-header {
          width: 100%;
          // margin-bottom: 8%;

          h4 {
            font-size: 28px;
            margin-bottom: 16px;
          }

          p {
            font-family: 'gilroy-regular';
            font-size: 20px;
          }
        }
      }
    }

    .mod-tech-tab-left-side {
      height: 0;
      opacity: 0;
      overflow: hidden;
      margin: 0 -40px;
      padding: 0 40px;

      h2 {
        width: 60%;
      }
    }
  }
}

@include media-breakpoint-down(xxl) {
  .mod-tech-tabs {
    .bottom-row {
      margin-top: 110px;
      height: 560px;
    }

    .tabs-switcher-row {
      margin-top: 110px;
    }
  }

  .tab-header {
    p {
      font-size: 20px;
    }
  }
}

@include media-breakpoint-down(xl) {
  .mod-tech-tabs {
    .bottom-row {
      margin-top: 64px;
      height: 460px;
    }

    .tabs-switcher-row {
      margin-top: 64px;
    }
  }

  .tab-header {
    p {
      font-size: 16px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .mod-tech-tabs {
    .tabs-switcher-row {
      margin-top: 32px;
      margin-left: 0;

      .tab-switcher {
        width: 100%;

        &.current-tab {
          .text-row {
            .tab-header {
              margin-bottom: 8%;
            }
          }
        }

        .text-row {
          padding-top: 8%;

          .tab-header {
            width: 100%;
            margin-left: 0;
            margin-bottom: 0;

            h4 {
              font-size: 18px;
              margin-bottom: 0;
            }

            p {
              font-size: 14px;
            }
          }
        }
      }
    }

    .mod-tech-tab-left-side {
      h3 {
        font-size: 22px;
        margin-bottom: 24px;
      }
    }

    .bottom-row {
      margin-top: 0;
      height: 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .mod-tech-tabs {
    .tabs-switcher-row {
      .text-row {
        height: 100%;

        .text-col {
          height: 100%;

          .tab-header {
            height: 100%;
          }
        }
      }
    }
  }
}
