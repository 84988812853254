.dots {
  position: absolute;

  .imgs-row {
    height: 15px;
  }

  img {
    display: inline;
    padding-right: 9px;
  }
}
