@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.faq-button {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 36px 0;
  padding: 24px 36px;
  width: 100%;
  cursor: pointer;

  img {
    width: 100%;
  }

  &.active {
    box-shadow: none;
    background-color: #fef4ed;
  }

  p {
    font-size: 16px;
  }
}

@include media-breakpoint-down(md) {
  .faq-button {
    .img-col {
      padding: 0;
    }
  }
}

@include media-breakpoint-only(lg) {
  .faq-button {
    .img-col {
      padding: 0;
    }
  }
}
