.mod-tech-img-find-issues {
  position: relative;
  height: 100%;

  .mod-tech-img-find-issues-ask,
  .group41,
  .group42,
  .group43 {
    position: absolute;
  }

  .mod-tech-img-find-issues-ask {
    top: -4%;
    left: 40%;
    width: 40%;
  }

  .group41 {
    top: 15%;
    left: 10%;
    width: 40%;
  }

  .group42 {
    top: 30%;
    right: 0;
    width: 36%;

    &.se {
      width: 34%;
    }
  }

  .group43 {
    top: 45%;
    left: 10%;
    width: 40%;
  }

  .dots {
    left: 5%;
    top: 75%;
    width: 40%;
  }
}
