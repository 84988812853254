.lang-switcher {
  .dropdown-toggle {
    width: 32px !important;
    height: 32px !important;
    padding: 0;
    border: none;
    font-size: 14px !important;

    &:focus {
      box-shadow: none;
    }

    &::after {
      content: none;
    }
  }

  .dropdown-menu {
    top: 120%;
    right: 0;
    box-shadow: 0px 18px 14px rgba(0, 0, 0, 0.09);
    border: 1px solid #dbdbdb;
    border-radius: 0px 0px 4px 4px;
  }
}
