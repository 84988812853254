@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.faq-section {
  margin-bottom: 128px;

  .container {
    padding-left: 5%;
    padding-right: 5%;

    .info-block-col {
      .faq-info-block {
        padding-top: 24px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .faq-section {
    margin-top: 20%;
  }
}
