.navbar {
  .navbar-nav {
    .products-dropdown {
      .dropdown-menu {
        top: 100%;
        width: 294px;
        background: #ffffff;
        box-shadow: 0px 18px 14px rgba(0, 0, 0, 0.09);
        border: 1px solid #dbdbdb;
        border-radius: 0px 0px 4px 4px;

        .dropdown-item {
          font-size: 14px;
        }

        .divider-row {
          padding: 4px 16px;
          margin: 0;
          width: 100%;

          .divider-col {
            padding: 0 6px;
          }
        }
      }

      .dropdown-toggle {
        margin-right: 0;

        &::after {
          display: none;
        }
      }
    }
  }
}
