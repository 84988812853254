@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.privacy-section {
  margin-bottom: 306px;

  h1 {
    margin-top: 146px;
    font-family: Roboto;
    font-size: 56px;
    font-style: normal;
    font-weight: bold;
    line-height: 56px;
    text-align: center;
    text-transform:capitalize;
  }

  h5 {
    font-family: Gilroy-SemiBold;
    font-size: 32px;
    text-transform: uppercase;
    margin-top: 48px;
    margin-bottom: 24px;
  }

  p,
  li {
    font-family: Gilroy-Regular;
    font-size: 20px;
    margin: 0;
  }

  li {
    list-style: none;

    &::before {
      content: '·';
      vertical-align: middle;
      font-size: 36px;
      line-height: 18px;
      padding-right: 8px;
    }

    &.bold-li {
      font-family: Gilroy-Bold;
      font-size: 24px;
      margin-top: 24px;
    }
  }

  ul {
    padding-left: 0;
  }

  .three-green-lines {
    margin: 40px auto 112px auto;

    .first {
      top: 0;
    }

    .third {
      bottom: 0;
    }

    .first,
    .third {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .updated {
    font-family: Gilroy-Bold;
    font-size: 22px;
  }
}

@include media-breakpoint-up(lg) {
  .privacy-section {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
}

@include media-breakpoint-down(lg) {
  .privacy-section {
    margin-bottom: 100px;

    h1 {
      margin-top: 80px;
    }

    .three-green-lines {
      margin-bottom: 60px;
    }
  }
}
