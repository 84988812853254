.three-faces {
  height: 44px;
  width: 120px;
  position: relative;

  img {
    position: absolute;
    top: 0;
  }

  .img1 {
    left: 0;
  }

  .img2 {
    left: 28%;
  }

  .img3 {
    left: 56%;
  }
}
