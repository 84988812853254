.mod-tech-img-recieve-report {
  position: relative;
  height: 100%;
  width: 100%;

  .rectangle2701,
  .dots22,
  .dots32,
  .group7987 {
    position: absolute;
  }

  .rectangle2701 {
    right: 5%;
    top: 0;
    width: 40%;
  }

  .dots22 {
    left: 30%;
    bottom: 10%;
  }

  .dots32 {
    left: 40%;
    bottom: 0;
  }

  .group7987 {
    left: 15%;
    top: 30%;
    width: 55%;
  }
}
