.login-inner {
  .logo {
    margin: 5% auto 10% auto;
    display: block;
    width: 23%;
  }

  h2 {
    margin-bottom: 8%;
  }

  .small-poppins {
    color: rgba(123, 123, 123, 0.8);
    font-size: 14px;
  }

  .pwd-label {
    width: 100%;
    position: relative;

    a {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
