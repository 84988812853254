@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.team-card {
  display: flex;
  position: relative;
  width: 100%;
  margin: 50px 0;

  .img-box {
    img {
      width: 110px;
    }
  }

  .notices-box {
    margin-left: 4.5%;

    .img-box {
      display: none;
    }

    h5 {
      text-transform: none;
    }

    .footer-font {
      min-height: 150px;
    }

    p {
      margin-bottom: 10px;
    }

    .linkedin {
      display: flex;
      align-items: center;

      .icon {
        width: 6%;
        margin-right: 3.4%;
        display: inline-block;
      }

      a {
        display: inline-block;
        text-decoration: underline;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-only(lg) {
  .team-card {
    .img-box {
      img {
        width: 100px;
      }
    }

    .notices-box {
      .footer-font {
        min-height: 180px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .team-card {
    margin: 30px 0;

    .img-box {
      display: none;
    }

    .notices-box {
      margin-left: 0;

      .ava-header {
        margin-bottom: 2%;

        .img-box {
          display: inline-block;
          width: 13%;

          img {
            width: 100%;
          }
        }

        h5 {
          display: inline;
          margin: 0 0 0 4%;
        }
      }

      .footer-font {
        min-height: 0;
        margin-bottom: 20px;
      }

      .linkedin {
        .icon {
          margin-right: 20px;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .team-card {
    .notices-box {
      .ava-header {
        margin-bottom: 4%;
      }
    }
  }
}
