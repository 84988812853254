@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.yellow-section {
  background-color: #ffeeb5;

  .left-col,
  .right-col {
    margin-top: 144px;
    margin-bottom: 164px;
  }

  .left-col {
    h1,
    p {
      margin-bottom: 42px;
    }
  }

  .btn {
    width: 216px;
  }

  .rectangle-img {
    position: absolute;
    width: 307px;
    max-width: 17vw;
    top: 0;
    left: 0;
  }

  .device-mashup-img {
    width: 1000px;
    max-width: 55vw;
    padding-top: 16px;
    z-index: 1;
  }

  .dots {
    position: absolute;
    left: 0;
    bottom: 10%;
  }
}

@include media-breakpoint-down(xxl) {
  .yellow-section {
    .left-col,
    .right-col {
      margin-top: 128px;
    }

    .left-col {
      h1,
      p {
        margin-bottom: 32px;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .yellow-section {
    .left-col,
    .right-col {
      margin-top: 112px;
    }

    .rectangle-img {
      top: 5%;
    }
  }
}

@include media-breakpoint-down(lg) {
  .yellow-section {
    .left-col {
      margin-top: 56px;
      margin-bottom: 0;

      h1,
      p {
        margin-bottom: 24px;
      }
    }

    .right-col {
      margin-top: 40px;
    }

    .btn {
      width: 152px;
    }

    .device-mashup-img {
      width: 100%;
      max-width: 100%;
    }

    .rectangle-img {
      width: 30%;
      max-width: 30%;
      left: 5%;
    }

    .dots {
      display: none;
    }
  }
}

@include media-breakpoint-down(md) {
}
