.three-green-lines {
  width: 130px;
  height: 30px;
  position: relative;

  .first,
  .second,
  .third {
    position: absolute;
  }

  .first {
    right: 0;
    top: 0;
  }

  .second {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .third {
    left: 0;
    bottom: 0;
  }
}
