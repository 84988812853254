@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.mobile-app-section {
  position: relative;
  margin-bottom: 100px;

  .pink-rectangle {
    z-index: -1;
    position: absolute;
    top: 0;
    height: 714px;
    width: 100%;
    background-color: #fef4ed;
  }

  .first-row {
    padding-top: 10%;
  }

  .green-marks-row {
    margin-bottom: 10%;
    margin-top: 2%;

    .green-mark {
      margin-top: 6%;
    }
  }

  .request-demo-button {
    margin-bottom: 5%;
    .btn {
      width: 214px;
    }
  }

  .three-iphones {
    border-bottom: 1px solid black;
    margin-bottom: 8%;

    .col {
      padding: 5%;
    }

    img {
      width: 100%;
      margin-bottom: 10%;
    }

    p {
      margin-left: -5%;
      margin-right: -5%;
    }

    .circle-num {
      background-color: #ffeeb5;
      width: 50px;
      height: 50px;
      margin-bottom: 10%;

      h3 {
        margin: 0;
      }
    }

    .carousel {
      margin-top: 5%;
      margin-bottom: 5%;

      img {
        width: 50%;
        margin-bottom: 5%;
      }

      .circle-num {
        margin-bottom: 4%;
        margin-left: auto;
        margin-right: auto;
      }

      p {
        padding-left: 15%;
        padding-right: 15%;
      }
    }
  }

  .play-your-part-row {
    .iphone12-1-img,
    .iphone12-4-img {
      position: absolute;
      width: 60%;
      bottom: 5%;
    }

    .iphone12-1-img {
      left: 0;
    }

    .iphone12-4-img {
      right: 0;
    }

    .rectangle2100 {
      z-index: -1;
      width: 50%;
      position: absolute;
      top: 0;
      left: -20%;
    }

    h2 {
      margin-bottom: 12%;
    }

    h4 {
      margin-bottom: 8%;
    }

    p {
      margin-bottom: 20%;
      padding-right: 10%;
    }

    .icon {
      width: 20%;
      margin-bottom: 6%;
    }
  }

  .calendar-row,
  .my-pages-row {
    .carousel {
      margin-top: 10%;
      margin-bottom: 20%;
      img {
        height: 520px;
        max-width: 100%;
        margin-bottom: 10%;
      }
    }

    margin-top: 5%;

    .calendar,
    .event-management2 {
      margin-top: 50%;
      margin-bottom: 10%;
      width: 25%;
    }

    h2 {
      margin-bottom: 10%;
    }

    .group7957 {
      padding-right: 10%;
    }

    .group7955,
    .group7957 {
      width: 100%;
    }
  }
}

@include media-breakpoint-down(lg) {
  .mobile-app-section {
    .three-iphones {
      border-bottom: none;
      margin-bottom: 20%;
    }

    .play-your-part-row {
      .one-of-four-block {
        padding-left: 5%;
      }
    }

    .calendar-row,
    .my-pages-row {
      margin-top: 20%;

      .carousel {
        margin-top: 5%;
        margin-bottom: 5%;
        img {
          height: 720px;
          max-width: 100%;
          margin-bottom: 10%;
        }
      }

      .calendar,
      .event-management2 {
        margin-top: 0;
        width: 20%;
        margin-bottom: 3%;
      }

      h2 {
        margin-bottom: 3%;
      }

      p {
        padding-left: 5%;
        padding-right: 5%;
      }
    }
  }
}
