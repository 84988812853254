.faq-button-block {
  .faq-info-block {
    transition: all 500ms 0ms;
    max-height: 600px;

    &:not(.active) {
      transition: all 500ms 0ms;
      max-height: 0px;
      overflow: hidden;
    }
  }
}
