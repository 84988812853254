@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.subscribe-section {
  .row {
    padding: 10%;

    .dots {
      position: absolute;
      left: 0;
      top: 0;
    }

    .rectangle2100 {
      position: absolute;
      left: 10%;
      top: 10%;
      z-index: -1;
      width: 70%;
    }

    .left-side {
      padding: 20% 10% 0 10%;

      input {
        border-left: none;
        border-right: none;
        border-top: none;
        border-radius: 0;
        box-shadow: none;
        margin-bottom: 24px;
      }

      .btn {
        width: 40%;
      }
    }
  }

  .right-img {
    width: 100%;
  }
}

@include media-breakpoint-down(lg) {
  .subscribe-section {
    .row {
      .left-side {
        .btn {
          width: 60%;
        }
      }
    }
  }
}
