@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.checkbox2 {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  margin: 40px 0;
  padding: 24px 80px 24px 40px;
  width: 100%;

  p {
    margin: 0;
  }

  img {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@include media-breakpoint-down(lg) {
  .checkbox2 {
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 16px 16px 16px 24px;

    p {
      font-size: 14px;
      line-height: 150%;
    }
  }
}
