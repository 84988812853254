@import '../variables.scss';

.navbar {
  height: 98px;
  font-family: 'gilroy-bold';
  font-size: 16px;
  line-height: 150%;
  padding: 0 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);

  .btn {
    height: 48px;
    width: 168px;
    font-size: 16px;
  }

  .navbar-nav {
    margin-left: 56px;

    .active {
      border-bottom: 8px solid $primary;
      padding-bottom: 0;

      &.dropdown {
        a {
          padding-bottom: 0;
        }
      }
    }

    .nav-link,
    .dropdown {
      padding-left: 24px;
      display: flex;
      align-items: center;
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }

  .navbar-collapse {
    &:not(.show) {
      height: 100%;
    }
  }

  .collapsing {
    .request-demo-button,
    .login-mobile-button {
      display: none;
    }
  }

  .navbar-collapse.show,
  .collapsing {
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;
    height: 70vh;
    background-color: #fef4ed;
    box-shadow: 0px 18px 14px rgba(0, 0, 0, 0.09);
    border: 1px solid #dbdbdb;
    border-radius: 0px 0px 4px 4px;
    z-index: 1000;
    padding: 16px;

    .request-demo-button {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 16px;
    }

    .login-mobile-button {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 16px;
    }

    .navbar-nav {
      margin-left: 0;

      .nav-link {
        padding: 16px 0 !important;
        border-bottom: 1px solid #d2d2d2;
        color: black;
        font-family: gilroy-regular;
        font-size: 16px;
      }

      .dropdown-menu {
        position: absolute;
      }
    }
  }

  .navbar-toggler {
    border-color: transparent !important;

    .navbar-toggler-icon {
      background-image: url('../assets/images/radix-icons_hamburger-menu.svg') !important;
    }
  }
}
