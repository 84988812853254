@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.footer {
  background-color: black;
  color: white;
  padding-bottom: 5%;

  .first-row {
    padding-top: 10%;
  }

  p {
    margin: 0;
  }

  img {
    width: 120px;
    margin-bottom: 3%;
  }

  a {
    color: white;
    text-decoration: none;

    &:hover {
      color: lightgrey;
    }
  }

  .nav-row {
    margin-top: 3%;

    div {
      margin-bottom: 2%;
    }
  }

  .bottom-block {
    display: flex;
    justify-content: space-between;
    margin-top: 3%;

    .apps-block {
      display: flex;
      justify-content: space-between;
      width: 30%;

      .olivux,
      .googleplay,
      .appstore {
        width: 30%;

        div {
          width: 100%;
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      .olivux div {
        background-image: url('../assets/images/olivux.png');
      }

      .googleplay div {
        background-image: url('../assets/images/googleplay.png');
      }

      .appstore div {
        background-image: url('../assets/images/appstore.png');
      }
    }

    .texts-block {
      display: flex;
      justify-content: space-between;
      width: 25%;

      p {
        font-size: 16px;

        &.delimiter {
          color: gray;
        }
      }
    }
  }
}

@include media-breakpoint-down(xxl) {
  .footer {
    .bottom-block {
      .texts-block {
        width: 30%;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .footer {
    .bottom-block {
      .apps-block {
        width: 40%;
      }

      .texts-block {
        width: 36%;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .footer {
    img {
      margin-bottom: 2%;
    }
    .nav-row {
      margin-top: 10%;
      margin-bottom: 8%;
    }

    .address {
      border-top: 1px solid #9d9d9d;
      padding-top: 2%;
    }

    .bottom-block {
      .texts-block {
        margin-top: 6%;
        flex-direction: column-reverse;
        width: 100%;

        p {
          margin-top: 4%;
        }

        .delimiter {
          display: none;
        }
      }

      .apps-block {
        height: 50px;
        width: 100%;
        margin-top: 4%;
      }

      flex-direction: column-reverse;
    }
  }
}

@include media-breakpoint-down(md) {
  .footer {
    .bottom-block {
      .apps-block {
        margin-top: 6%;
        height: 36px;
      }

      .texts-block {
        margin-top: 10%;

        p {
          margin-top: 6%;
        }
      }
    }
  }
}
