@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.mod-tech-section {
  border-top-left-radius: 50% 100px;
  border-top-right-radius: 50% 100px;
  background-color: white;
  margin-bottom: 5%;

  .top-row {
    padding-top: 80px;
    margin-top: -100px;
    margin-bottom: 24px;
  }

  h2 {
    font-size: 46px;
    width: 75%;
  }
}

@include media-breakpoint-down(lg) {
  .mod-tech-section {
    border-top-left-radius: 50% 150px;
    border-top-right-radius: 50% 150px;

    .top-row {
      padding-top: 140px;
      margin-top: -250px;
    }

    h2 {
      font-size: 24px;
      width: 90%;
    }
  }
}

@include media-breakpoint-down(md) {
  .mod-tech-section {
    .top-row {
      padding-top: 120px;
    }
  }
}
